import ChatComponent, { chatWidth } from "./ChatComponent"
import ExhumanComponent from "./ExhumanComponent"
import './css/ChatWithExhumanComponent.css'
import ProfileWidget from "./ProfileWidget"
import HeaderComponent from "./HeaderComponent"
import { useSelector } from "react-redux"
import { selectCurrentAvatarConfig } from "../../app/redux/defaultSlice"
import { useEffect } from "react"
import { useNavigate } from "react-router-dom"

export default () => {
    const currentAvatar = useSelector(selectCurrentAvatarConfig)
    const navigate = useNavigate()

    useEffect(() => {
        if (!currentAvatar) {
            navigate('/')
        }
    }, [currentAvatar])

    return <div className='h-full'>
        <HeaderComponent/>
        <div className='main-block'>
            <div
                style={{
                    height: '100%',
                    padding: '24px 0px 32px 0px',
                    // background: 'orange'
                }}>
                <ExhumanComponent />
            </div>
            <div
                style={{
                    width: '100%',
                    minWidth: chatWidth + 50,
                    height: '100%',
                    marginBottom: 25,
                    padding: '0px 32px 32px 24px',
                    // background: 'blue'
                }}>
                <ChatComponent />
            </div>
        </div>
    </div>

}